import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ol>
      <li parentName="ol">
        <p parentName="li">{`I started to do simple trading investment on BTC perpetual market at `}<a href="https://ftx.com" target="_blank" rel="noopener">{`ftx.com`}</a>{` (zero fees on limit orders).
Til writing this post, I have earned 400+ USD with 2000 USD capital and each order has a 6000 USD notional.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`I was lucky sometimes that I bought on the local minimum and sold on peak; but that's not always the case.
I have once bought on the high point and the price goes straight down.
I believe the price will eventually goes up to my desired point. That price took around a day to go back.
So when the price drop, believe in BTC and just wait...`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`I could split my orders into multiple chunks.
Some weighted heavier and take lower risk (earn less); the others are placed on ambitious closing point.`}</p>
      </li>
    </ol>
    <h2>{`A few questions`}</h2>
    <ul>
      <li parentName="ul">{`Can I automate these?`}</li>
      <li parentName="ul">{`When my capital grow, can the market take my order? (or will my order affect the market?)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      