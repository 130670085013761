import { PageProps } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import { LinkContainer } from "../components/LinkContainer";

const PostLayout: React.FC<PageProps> = ({ children, pageContext }) => {
  const { title, tags } = (pageContext as any).frontmatter;
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container mx-auto px-10 py-20 min-h-screen">
        <article className="prose prose-slate mx-auto font-serif">
          <h1>{title}</h1>
          <div className="flex flex-row space-x-2">
            {tags.map((tag: string) => (
              <span key={tag} className="text-sm">
                <LinkContainer to="/">
                  {/* TODO */}#{tag}
                </LinkContainer>
              </span>
            ))}
          </div>
          {children}
        </article>
      </main>
    </>
  );
};

export default PostLayout;
